.App {
  text-align: center;
}

.App-logo {
  transform: rotate(30deg);
  animation: logo-spin 30s cubic-bezier(.18, 0, 0, .1) 1s, logo-spin 30s linear 31s infinite;
  fill: #DDD;

  .App-logo-outer-hex {
    animation: pulsesvg-start 2s ease-in 0.5s 1 forwards, pulsesvg 80s linear 2.5s infinite alternate;
    fill: #DDD;
  }

  .App-logo-inner-hex {
    fill: #111;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@keyframes pulsesvg {
  0% { fill: #b2cefe; }
  20% { fill: #f2a2e8; }
  40% { fill: #fea3aa; }
  60% { fill: #f8b88b; }
  80% { fill: #faf884; }
  100% { fill: #baed91; }
}

@keyframes pulsesvg-start {
  from { fill: #DDD; }
  to { fill: #b2cefe; }
}

@keyframes pulse {
  0% { color: #b2cefe; }
  20% { color: #f2a2e8; }
  40% { color: #fea3aa; }
  60% { color: #f8b88b; }
  80% { color: #faf884; }
  100% { color: #baed91; }
}

@keyframes pulse-start {
  from { color: #DDD; }
  to { color: #b2cefe; }
}

@keyframes logo-spin {
  from { transform: rotate(30deg); }
  to { transform: rotate(390deg); }
}
